<template>
  <div>
    <v-row class="ma-1">
      <v-col cols="12" class="d-flex align-center justify-space-between" no-gutters>
        <h2>{{ $t("sideBar.news") }}</h2>
        <v-col cols="auto" class="pa-0">
          <v-btn
              v-if="permissions.can_add_edit_article"
              @click="openAddEditNewsDialog"
              block
              depressed
              large
              type="submit"
              class="btn-blue-bg text-transform-none"
              style="font-size: 16px; font-weight: 500"
          >
          {{ $t("btn.addNews") }}
          </v-btn>
        </v-col>
      </v-col>

      <v-col @click="openAddEditNewsDialog(article)" cols="12" class="newsCol" v-for="(article, index) in articlesList" :key="index">
        <v-card flat>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-img
                  v-if="!article.image"
                  src="https://via.placeholder.com/400x300"
                  alt="News Image"
                  class="news-image"
              ></v-img>
              <v-img
                  v-if="article.image"
                  :src="article.image"
                  alt="News Image"
                  class="news-image"
                  contain
              ></v-img>
              <v-chip
                  v-if="isNewArticle(article.created_at)"
                  color="blue"
                  dark
                  class="ma-2 news-status"
                  label
                  small
              >
              {{ $t("form.new") }}
              </v-chip>
            </v-col>
            <v-col cols="12" md="8" class="d-flex flex-column justify-space-between">
              <v-card-title class="headline font-weight-bold mb-2">
                {{article.title}}
              </v-card-title>
              <v-card-subtitle class="body-1 grey--text mb-2 truncate-text">
                {{ article.text }}
              </v-card-subtitle>
              <v-card-actions>
                <v-chip color="grey lighten-1" label small class="ma-2">
                  {{ $t("form.important") }}
                </v-chip>
                <v-chip color="grey lighten-1" label small class="ma-2">
                  {{ $t("form.worldNews") }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-icon small class="mr-1">mdi-calendar</v-icon>
                <span>{{ formatDateTime(article.created_at) }}  </span>
                <v-icon small class="ml-4 mr-1">mdi-eye</v-icon>
                <span>122</span>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-row justify="center">
        <v-col cols="auto">
          <v-pagination
              v-model="pagination.page"
              :length="quantityPage"
              @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-row>
  <add-edit-news-dialog v-if="articleDialog" :visible="articleDialog" :article="article" @close="articleDialog = false" @articleCreated="articleCreated" :type="articleDialogType"/>
  </div>
</template>

<script>
import newsService from "@/services/request/news/newsService";
import AddEditOfferDialog from "@/components/offersCatalog/addEditOfferDialog.vue";
import AddEditNewsDialog from "@/components/news/addEditNewsDialog.vue";
import ArticleDetailsPage from "@/components/news/articleDetailsPage.vue";
import user from "@/mixins/user";

export default {
  name: "NewsComponent",
  components: {ArticleDetailsPage, AddEditNewsDialog, AddEditOfferDialog},
  mixins: [user],
  data: () => ({
    articlesList: [],
    article: {
      image: "",
      title: "",
      text: ""
    },
    articleDialog: false,
    articleDialogType: 'create',
    quantityPage: 0,
    pagination: {
      page: 1,
      itemsPerPage: 15
    }
  }),
  async mounted() {
    this.setLoading(true);
    await this.fetchArticles(1);
    this.setLoading(false);
  },
  methods: {
    async fetchArticles(page) {
      try {
        const params = {};
        params.page = page;
        params.per_page = 8;
        const data = await newsService.getArticles(params)
        this.articlesList = data.data;
        this.quantityPage = data.pagination.meta.last_page;
      } catch (e) {
        console.log(e);
      }
    },
    openAddEditNewsDialog(item) {
      if(item?.id) {
        // this.articleDialogType = 'edit';
        // this.article = item;
        // this.articleDialog = true;
        this.openInfoPage(item);
      } else {
        this.articleDialogType = 'create';
        this.article = {};
        this.articleDialog = true;
      }
    },
    openInfoPage(item) {
      this.$router
          .push({
            name: "article-details",
            params: {
              article: item.id
            }
          })
          .catch(() => {});
    },
    async articleCreated(createdArticle) {
      /*if(this.pagination.page == 1) {
        this.articlesList?.unshift(createdArticle);
      } else {*/
        this.pagination.page = 1;
        await this.fetchArticles(1);
    },
    /*articleModified(modifiedArticle) {
      const index = this.articlesList?.findIndex((item) => item.id === modifiedArticle.id);
      if (index !== -1) {
        this.$set(this.articlesList, index, modifiedArticle);
      }
    },*/
    onPageChange(page) {
      this.fetchArticles(page);
    },
    isNewArticle(dateCreated) {
      const createdDate = new Date(dateCreated).getTime();
      const currentDate = Date.now();

      const oneDayInMs = 24 * 60 * 60 * 1000;

      return (currentDate - createdDate) <= oneDayInMs;
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
  },
};
</script>

<style scoped>
.newsCol {
  background: #F8F8FF;
  margin-bottom: 5px;
  border-radius: 10px;

  .theme--dark & {
    background: #262626;
  }

}

.news-image {
  border-radius: 10px;
  max-height: 200px;
}

.news-status {
  position: absolute;
  top: 10px;
  left: 10px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-height: 1.4em;
  max-height: calc(1.4em * 3);
  white-space: normal;
}

@media (min-width: 960px) {
  .news-image {
    height: 100%;
  }
  .truncate-text {
    -webkit-line-clamp: 2;
    max-height: calc(1.4em * 2);
  }
}
</style>
