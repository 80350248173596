<template>
  <NewsComponent />
</template>


<script>

import NewsComponent from "@/components/news/newsComponent.vue";

export default {
  name: "NewsView",
  components: {
    NewsComponent,
  },
};
</script>